import React, { useEffect, useState } from 'react'
import { CiCreditCard1 } from "react-icons/ci";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { BsClockHistory } from "react-icons/bs";
import { IoSearch, IoBagHandle } from "react-icons/io5";
import { RiLockPasswordLine, RiBatteryChargeLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import Avtarboy1 from "../images/Avtarboy1.jpg"
import Avtarboy2 from "../images/Avtarboy2.jpg"
import Avtarboy3 from "../images/Avtarboy3.jpg"
import Avtarboy4 from "../images/Avtarboy4.jpg"
import Avtargirl4 from "../images/Avtargirl4.jpg"
import Avtargirl3 from "../images/Avtargirl3.jpg"
import Avtargirl2 from "../images/Avtargirl2.jpg"
import Avtargirl1 from "../images/Avtargirl1.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdRefresh } from "react-icons/io";
import PhoneInput from 'react-phone-input-2';
import MoonLoader from "react-spinners/ClipLoader";

const Profile = () => {
  const history = useNavigate()
  const [Loadingt, setLoadingt] = useState(false);
  const [Loadingf, setLoadingf] = useState(false);
  const [Loadingallrecharges, setLoadingallrecharges] = useState(false);
  const [Loadingrecharge, setLoadingrecharge] = useState(false);

  const [profile, setProfile] = useState("");
  const [withdrawals, setmywithdrawal] = useState([]);
  const [recharges, setrecharges] = useState([]);
  const [user, setuser] = useState(null);
  const [trueinfo, settrueinfo] = useState([]);
  const [accept, setaccept] = useState(null);
  const [useremmail, setuseremmail] = useState("");
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedElement, setSelectedElement] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOptionrecharge, setSelectedOptionrecharge] = useState(null);
  const [takeactionb, SetTakeactionb] = useState("")
  const [UTR, SetUTR] = useState("")


  useEffect(() => {
    if (selectedOptionrecharge == "") {
      setSelectedOptionrecharge(null)
    }
  }, [selectedOptionrecharge])
  useState(() => {
    if (!localStorage.getItem("token") || !localStorage.getItem("user") || !localStorage.getItem("Avtar")) {
      history("/login")
    }
  }, [])
  useEffect(() => {

    if (!localStorage.getItem("token")) {
      history("/Login")
    }
    if (localStorage.getItem("user")) {

      setProfile((JSON.parse(localStorage.getItem("user")).avtar));
    }
    if ((localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).email) === process.env.REACT_APP_CONTROL) {
      const Withdrawals = async () => {
        const response = await fetch(`${process.env.REACT_APP_GAME}`, {
          method: 'GET',
          headers: {
            'Content-type': "application/json",

          },
        });
        const json = await response.json()

        setmywithdrawal(json)
      }
      Withdrawals()
    }
  }, [])

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleClick = (element) => {
    setSelectedElement((prevSelectedElement) =>
      prevSelectedElement === element ? null : element
    );

  };

  const getuser = async () => {
    if (useremmail == "") {

      toast.info("Please enter user email", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    } else {


      const response = await fetch(`${process.env.REACT_APP_GAME_USERINFO}`, {
        method: 'POST',
        headers: {
          'Content-type': "application/json",
          'auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({ email: useremmail })

      });
      const json = await response.json()
      if (json.success) {
        setuser(json.user)
      }
      else {
        setuser(null)
        toast.error("User not found", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
    }

  }
  const handleaccept = async () => {
    const response = await fetch(`${process.env.REACT_APP_GAME_WITHDRAWALTRUE}`, {
      method: 'POST',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ withdrawalId: selectedElement._id })

    });
    const json = await response.json()
    if (json.success) {
      const response = await fetch(`${process.env.REACT_APP_GAME}`, {
        method: 'GET',
        headers: {
          'Content-type': "application/json",

        },
      });
      const json = await response.json()

      setmywithdrawal(json)

      toast.success("Accept  withdrawal successfully", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
  }
  const cr = async (value) => {

    const response = await fetch(`${process.env.REACT_APP_GAME_TRUEC}`, {
      method: 'POST',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ result: value })

    });
    const jsonX = await response.json()
    if (jsonX.success) {
      if (jsonX.already) {
        if (jsonX.cr) {
          setLoadingf(false)
          setLoadingt(false)
          toast.info(`${jsonX.error} RED`, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

          });
        } else {
          setLoadingf(false)
          setLoadingt(false)
          toast.info(`${jsonX.error} GREEN`, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

          });
        }

      } else {
        setLoadingf(false)
        setLoadingt(false)
        toast.success(`Set result ${jsonX.cr ? "RED" : "GREEN"}`, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
    } else {
      setLoadingf(false)
      setLoadingt(false)
      toast.error(`Error`, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
  }
  const handlereject = async () => {
    const response = await fetch(`${process.env.REACT_APP_GAME_WITHDRAWALFALSE}`, {
      method: 'POST',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ withdrawalId: selectedElement._id })

    });
    const jsonX = await response.json()
    if (jsonX.success) {
      const response = await fetch(`${process.env.REACT_APP_GAME}`, {
        method: 'GET',
        headers: {
          'Content-type': "application/json",

        },
      });
      const json = await response.json()

      setmywithdrawal(json)

      toast.success("Reject  withdrawal successfully", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
  }

  const handlerecharge = async () => {
    if ((UTR.length) != 12) {
      toast.info("Enter 12 digit UTR", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
    else {
      setLoadingrecharge(true)
      const response = await fetch(`${process.env.REACT_APP_GAME_RECHARGE}`, {
        method: 'POST',
        headers: {
          'Content-type': "application/json",
          'auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({ utr: UTR })

      });
      const jsonX = await response.json()
      if (jsonX.success) {
        setLoadingrecharge(false)


        toast.success("Recharge successfully", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
      else {
        setLoadingrecharge(false)

        toast.error(jsonX.error, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
    }
  }
  const getrecharges = async () => {
    setLoadingallrecharges(true);
    const response = await fetch(`${process.env.REACT_APP_GAME_RECHARGES}`, {
      method: 'GET',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem('token')
      },

    });
    const jsonX = await response.json()
    setLoadingallrecharges(false)
    toast.success("Updated successfully", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,

    });
    setrecharges(jsonX)

  }
  const handletakeaction = (e) => {
    SetTakeactionb(e.target.value);
  };
  const trueresult = async () => {
    const response = await fetch(`${process.env.REACT_APP_GAME_TRUEINFO}`, {
      method: 'GET',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem('token')
      },

    });
    const jsonX = await response.json()
    if (jsonX.success) {
      settrueinfo(jsonX.trueinfo)
      toast.success("Updated successfully", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
    else {
      toast.error(jsonX.error, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
  }
  const takeaction = async () => {
    if (takeactionb === "") {
      toast.info("Enter action balance", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
    else {

      const response = await fetch(`${process.env.REACT_APP_GAME_ACTION}`, {
        method: 'POST',
        headers: {
          'Content-type': "application/json",
          'auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({ amount: takeactionb, userid: user._id })

      });
      const jsonX = await response.json()
      if (jsonX.success) {
        toast.success("Updated successfully", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
      else {
        toast.error(jsonX.error, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
    }
  }
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-[90%] my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className={`flex  items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t`}>

                  <label htmlFor="contract4" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white    `}>
                    <div className="block">
                      <div className="w-full text-lg font-semibold">Are you sure to Log out </div>
                    </div>

                  </label>


                </div>
                <form action="#" >


                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded ">
                    <button
                      className="mx-5 bg-slate-100 pt-2 pb-2 pr-3 pl-3 rounded shadow"
                      type="button"
                      onClick={(() => {
                        setShowModal(false)
                      })}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 flex items-center gap-1 pt-2 pb-2 pr-3 pl-3  rounded text-white font-bold shadow-xl "
                      type="submit"
                      onClick={(() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        localStorage.removeItem("Avtar");
                        localStorage.removeItem("modalShown");
                        window.location.reload(false)
                      })}
                    >
                      Log out
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40  bg-black"></div>

        </>
      ) : null
      }

      {(localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).email) !== process.env.REACT_APP_CONTROLX && (localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).email) !== process.env.REACT_APP_CONTROLY ?
        <div className='flex flex-col mb-20  items-center justify-center'>
          <div className='container mt-7 md:mt-0  p-2 '>
            <div className="flex flex-col   rounded-xl     p-4">

              <div className=" mb-2 md:mb-5  flex items-center justify-center ">
                {profile === "Avtarboy1" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtarboy1} alt="Profile" />

                }
                {profile === "Avtarboy2" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtarboy2} alt="Profile" />
                }
                {profile === "Avtarboy3" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtarboy3} alt="Profile" />
                }
                {profile === "Avtarboy4" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtarboy4} alt="Profile" />
                }
                {profile === "Avtargirl1" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtargirl1} alt="Profile" />
                }

                {profile === "Avtargirl2" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtargirl2} alt="Profile" />
                }

                {profile === "Avtargirl3" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtargirl3} alt="Profile" />
                }

                {profile === "Avtargirl4" &&
                  <img className='w-[30%] md:w-[10%]  rounded-full shadow-2xl' src={Avtargirl4} alt="Profile" />
                }

              </div>

              <div className="mb-2 md:mb-5 ">
                <p className='text-center font-bold text-xl italic'>~$~  {localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).name}  ~$~</p>
              </div>
              <div className="flex  items-center  justify-center">

                <div className="flex gap-1 flex-col ">
                  <p className='flex font-semibold items-center justify-center'>Available Balance : <LiaRupeeSignSolid className='text-xl' />{localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).balance}  </p>
                  <p className='flex font-semibold items-center justify-center'>Game Credits : <LiaRupeeSignSolid className='text-xl' />{localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).GameCredits}</p>
                </div>
              </div>

            </div>

          </div>
          <div className='container md:w-[70%]'>
            <div className="flex  mb-10 justify-around   flex-col  gap-2">

              <Link to={"/Recharge"}>
                <div className="mr-3 ml-3  flex items-center justify-between cursor-pointer  p-5 rounded-xl   bg-slate-50">
                  <p>Recharge</p>
                  <RiBatteryChargeLine className='text-2xl' />
                </div>
              </Link>
              <Link to={"/Permotion"}>
                <div className="mr-3 ml-3  flex items-center justify-between cursor-pointer  p-5 rounded-xl   bg-slate-50">
                  <p>Promotion</p>
                  <IoBagHandle className='text-2xl' />
                </div>
              </Link>
              <Link to={"/Withdrawal"}>

                <div className="mr-3 ml-3  flex items-center justify-between cursor-pointer  p-5 rounded-xl   bg-slate-50">
                  <p>Withdrawal</p>
                  <LiaRupeeSignSolid className='text-2xl' />
                </div>
              </Link>
              <Link to={"/Bankcard"}>

                <div className="mr-3 flex items-center justify-between ml-3 cursor-pointer  p-5 rounded-xl   bg-slate-50">
                  <p>Bank Card</p>
                  <CiCreditCard1 className='text-2xl' />
                </div>
              </Link>
              <Link to={"/History"}>
                <div className=" mr-3 flex items-center justify-between  ml-3 cursor-pointer p-5 rounded-xl   bg-slate-50">
                  <p>History</p>
                  <BsClockHistory className='text-2xl' />
                </div>
              </Link>
              <Link to="/forgot">
                <div className=" mr-3 flex items-center justify-between  ml-3 cursor-pointer p-5 rounded-xl   bg-slate-50">

                  <p>Security</p>
                  <RiLockPasswordLine className='text-2xl' />
                </div>
              </Link>
            </div>
            <div className="flex  items-center justify-center">
              <button onClick={(() => {
                setShowModal(true)
              })} className='bg-slate-100 shadow hover:bg-slate-300  rounded pt-2 pb-2 pr-5 pl-5 btn'>Log out</button>
            </div>
          </div>
        </div> : ""}
      {(localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).email) == process.env.REACT_APP_CONTROLX &&
        <div className='flex flex-col mb-20  items-center justify-center'>
          <div className='container  mt-3 flex justify-center items-center  mb-3  p-2 '>
            <div className="flex flex-col md:w-[60%]    p-4">
              <div className=" flex mb-5  items-center gap-2  items-center ">

                {profile === "Avtargirl4" &&
                  <img className='w-[15%] md:w-[10%]  rounded-full shadow-xl cursor-pointer ' src={Avtargirl4} alt="Profile" />
                }

                <div className=" flex w-full items-center justify-between ">
                  <p className='font-bold  md:text-2xl '>~$~  {localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).name}  ~$~</p>
                  <Link to={"/Recharge"}>  <button className='bg-slate-100 shadow rounded-xl pt-2 pb-2 pr-5 pl-5 '>Recharge</button></Link>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className='flex   items-center '>Available Balance : <LiaRupeeSignSolid className='text-xl' />{localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).balance}  </p>
                <IoMdRefresh onClick={() => {
                  window.location.reload(false)
                }} className='text-2xl cursor-pointer text-slate-400' />
              </div>

            </div>
          </div>
          <div className="flex justify-center overflow-auto w-[100%] md:w-[50%] control-box items-center  flex-row  justify-evenly">
            <input type="radio" id="contract1" name="hosting" value={1} className="hidden peer" required onChange={handleOptionChange} />
            <label htmlFor="contract1" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 1 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Update</div>
              </div>

            </label>
            <input type="radio" id="contract2x" name="hosting" value={22} className="hidden peer" onChange={handleOptionChange} />
            {/* <label htmlFor="contract2x" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 2 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">AIPred</div>

              </div>

            </label> */}
            <input type="radio" id="contract2" name="hosting" value={2} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract2" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 2 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Recharge</div>

              </div>

            </label>
            <input type="radio" id="contract3" name="hosting" value={3} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract3" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 3 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Withdrawals</div>

              </div>

            </label>
            <input type="radio" id="contract4" name="hosting" value={4} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract4" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 4 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Userinfo</div>
              </div>

            </label>
            <input type="radio" id="contractk" name="hosting" value={5} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contractk" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 5 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Action</div>
              </div>

            </label>

          </div>
          {selectedOption == 1 &&
            <div className=" m-auto flex    h-[50vh] flex-col  mt-10 justify-center  w-[90%]   ">
              <div className="flex flex-col mb-10  w-full gap-10   items-center  justify-between  ">
                <div className="flex w-full  items-center gap-5  justify-between">
                  <p className='w-1/2'>Change result to Red </p>
                  <button onClick={() => {
                    setLoadingt(true)
                    cr(true)
                  }}
                    className=' flex items-center gap-3 justify-center pl-5 w-1/2 pr-5 pt-3 pb-3 border bg-red-500 active:bg-black text-white rounded'>
                    Red
                    <MoonLoader color={"white"} loading={Loadingt} size={20} aria-label="CircleLoader" /></button>
                </div>
                <div className="flex w-full  items-center gap-5  justify-between">
                  <p className='w-1/2'>Change result to Green </p>
                  <button onClick={() => {
                    setLoadingf(true)

                    cr(false)
                  }} className='flex items-center gap-3 justify-center pl-5 w-1/2  pr-5 pt-3 pb-3 border bg-green-500 active:bg-black text-white rounded'>Green

                    <MoonLoader color={"white"} loading={Loadingf} size={20} aria-label="CircleLo//ader" />

                  </button>
                </div>


              </div>
              <>
                {trueinfo.map((e) => {

                  return <div key={e._id}>
                    <div className="flex   p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Total Loss</p>
                      <p className=' w-1/2 text-end'>{e.loss}</p>
                    </div>
                    <div className="flex   p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Moneycontract</p>
                      <p className=' w-1/2 text-end'>{e.moneycontract}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>red</p>
                      <p className=' w-1/2 text-end'>{e.red}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>green</p>
                      <p className=' w-1/2 text-end'>{e.green}</p>
                    </div>
                  </div>
                })}
                <div className="flex items-center mt-10 justify-end">

                  <button onClick={trueresult} className='bg-purple-500 text-white  shadow active:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Update</button>
                </div>
              </>
            </div>}
          {selectedOption == 3 &&
            <>

              <div className="h-[50vh] flex items-center flex-col overflow-auto border mb-5 result-container pb-3 pr-3 pl-3 m-auto w-full md:w-[80%]">


                {withdrawals.map((element) => {
                  return <div className="md:w-[60%] mb-3 mt-3  w-[100%] " key={element._id} onClick={() => {

                  }}>
                    <div className="flex w-full  pt-2 pb-2 pr-2 pl-2 border  items-center  justify-between ">

                      <div className=" flex w-[100%] items-center  justify-between ">

                        <div className="w-1/3 "><p>{element.ammount}</p></div>


                        <div className="w-1/3 text-center" ><button onClick={(() => {
                          handleClick(element)
                          setaccept(false)
                        })} className='bg-red-500 text-white  shadow active:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Reject</button></div>
                        <div className="w-1/3 text-end"><button onClick={(() => {
                          handleClick(element)
                          setaccept(true)
                        })} className='bg-green-500 text-white shadow active:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Accept</button></div>

                      </div>
                      <div className="">
                      </div>
                    </div>
                    {selectedElement === element && (
                      <div className=" mb-2 mt-2 pl-2">
                        <div className="flex p-0.5 items-center  justify-between ">
                          <p className=' w-1/2 '>Amount</p>
                          <p className=' w-1/2'>{element.ammount}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>Bank card</p>
                          <p className=' w-1/2'>{element.upi}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>Status</p>
                          <div className={`${element.status == "Applied" ? "text-purple-700 " : ""}${element.status == "Success" ? "text-green-700 " : ""}${element.status == "Reject" ? "text-red-700 " : ""}  w-1/2`}><p>{element.status}</p></div>

                        </div>
                        <div className="flex items-center  justify-end">

                          {accept ? <button onClick={handleaccept} className='bg-green-500 text-white shadow hover:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Accept</button> :
                            <button onClick={handlereject} className='bg-red-500 text-white  shadow hover:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Reject</button>}
                        </div>
                      </div>)}


                  </div>
                })}
              </div>
            </>

          }
          {selectedOption == 2 &&
            <>
              <div className='h-[100vh]  w-full md:w-[70%]'>

                <div className="flex items-center mt-10 mb-10 justify-center gap-2 pl-3 pr-3 pt-3 pb-3 pl-5 border shadow rounded-xl md:text-xl  font-bold">
                  <p className='  md:text-xl font-bold '>UTR :</p>
                  <input onChange={((e) => { SetUTR(e.target.value) })} maxLength={5} required type="number" className='placeholder:text-blue-500 ring-inset   focus:ring-2 focus:ring-inset focus:ring-blue-600 p-3 ' placeholder='Input 12-digit here' />
                </div>
                <div className="flex justify-center mb-10 mt-10">
                  <button disabled={Loadingrecharge} onClick={handlerecharge} className='pt-3 pb-3 pl-20 pr-20 text-white active:bg-black bg-blue-500 font-bold text-xl shadow flex items-center gap-3   border rounded-xl' >Recharge
                  <MoonLoader color={"white"} loading={Loadingrecharge} size={20} aria-label="CircleLoader" />
                  </button>
                </div>
                <div className="flex justify-center mb-10 mt-10">
                  <button disabled={Loadingallrecharges} onClick={getrecharges} className='pt-3 pb-3 pl-20 pr-20 active:bg-black flex items-center gap-3 text-white bg-purple-500 font-bold text-xl shadow  border rounded-xl' >Recharge Update

                    <MoonLoader color={"white"} loading={Loadingallrecharges} size={20} aria-label="CircleLoader" />

                  </button>
                </div>
                {recharges.map((element) => {
                  return <div className="md:w-[60%] mb-3 mt-3  w-[100%] " key={element._id} onClick={() => {

                  }}>
                    <div className="flex w-full  pt-2 pb-2 pr-2 pl-2 border  items-center  justify-between ">

                      <div className=" flex w-[100%] items-center  justify-between ">

                        <div className="w-1/3 "><p>{element.amount}</p></div>



                        <div className="w-1/3 text-end"><button onClick={(() => {
                          handleClick(element)
                          setaccept(true)
                        })} className='bg-slate-100  shadow active:bg-slate-200 rounded pt-2 pb-2 pr-5 pl-5 btn'>Details</button></div>

                      </div>

                    </div>
                    {selectedElement === element && (
                      <div className=" mb-2 mt-2 pl-2">
                        <div className="flex p-0.5 items-center  justify-between ">
                          <p className=' w-1/2 '>Amount</p>
                          <p className=' w-1/2'>{element.amount}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>UTR</p>
                          <p className=' w-1/2'>{element.UTR}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>Status</p>
                          <div className={` w-1/2`}><p>{element.status}</p></div>

                        </div>

                      </div>)}
                  </div>

                })}
              </div>

            </>}
          {selectedOption == 4 &&
            <div className='h-[50vh]'>
              <div className="flex items-center gap-5 mt-10 mb-20 justify-between ">
                <PhoneInput country={"in"} value={useremmail} onChange={(e) => setuseremmail("+" + e)} placeholder='Enter Phone Number' />

                <IoSearch className='text-purple-600 text-3xl cursor-pointer ' onClick={getuser} />
              </div>
              {user &&

                <div className=" mb-2  mt-2 pl-2">
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>ID</p>
                    <p className=' w-1/2'>{user._id}</p>
                  </div>
                  <div className="flex p-0.5 items-center  justify-between ">
                    <p className=' w-1/2 '>Name</p>
                    <p className=' w-1/2'>{user.name}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Phone</p>
                    <p className=' w-1/2'>{user.email}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Bank card</p>
                    <p className=' w-1/2'>{user.upi}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Blanace</p>
                    <p className=' w-1/2'>{user.balance}</p>
                  </div>



                </div>
              }
            </div>}
          {selectedOption == 5 &&
            <div className='h-[70vh]  pl-2  pr-2'>
              <div className="flex items-center gap-5 mt-10 mb-20 justify-between ">
                <PhoneInput country={"in"} value={useremmail} onChange={(e) => setuseremmail("+" + e)} placeholder='Enter Phone Number' />

                <IoSearch className='text-purple-600 text-3xl cursor-pointer ' onClick={getuser} />
              </div>
              {user &&
                <>
                  <div className=" mb-2 text-sm  mt-2 ">
                    <div className="flex p-0.5 items-center   ">
                      <p className=' w-1/2 '>ID</p>
                      <p className=' w-1/2 '>{user._id}</p>
                    </div>
                    <div className="flex p-0.5 items-center  justify-between ">
                      <p className=' w-1/2 '>Name</p>
                      <p className=' w-1/2'>{user.name}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Email</p>
                      <p className=' w-1/2'>{user.email}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Bank card</p>
                      <p className=' w-1/2'>{user.upi}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Blanace</p>
                      <p className=' w-1/2'>{user.balance}</p>
                    </div>

                  </div>
                  <div className=" flex flex-col w-full mt-10">
                    <p className='mb-5'>Take action </p>

                    <input type="text" onChange={handletakeaction} className='border mb-10 rounded-xl pl-5 pr-5 pt-2 pb-2' placeholder='Enter balance...' />
                    <button onClick={takeaction} className='border pl-7 pr-7 pt-2 pb-2 bg-blue-600  rounded text-white active:bg-blue-500'>Confirm</button>
                  </div>
                </>

              }
            </div>}
          <div className="flex mt-20 items-center justify-center">
            <button onClick={(() => {
              setShowModal(true)

            })} className='bg-slate-100 shadow hover:bg-slate-300  rounded pt-2 pb-2 pr-5 pl-5 btn'>Log out</button>
          </div>
        </div>}
      {(localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).email) == process.env.REACT_APP_CONTROLY &&

        <div className='flex flex-col mb-20  items-center justify-center'>
          <div className='container  mt-3 flex justify-center items-center  mb-3  p-2 '>
            <div className="flex flex-col md:w-[60%]    p-4">
              <div className=" flex mb-5  items-center gap-2  items-center ">

                {profile === "Avtarboy2" &&
                  <img className='w-[15%] md:w-[10%]  rounded-full shadow-xl cursor-pointer ' src={Avtargirl4} alt="Profile" />
                }

                <div className=" flex w-full items-center justify-between ">
                  <p className='font-bold  md:text-2xl '>~$~  {localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).name}  ~$~</p>
                  <Link to={"/Recharge"}>  <button className='bg-slate-100 shadow rounded-xl pt-2 pb-2 pr-5 pl-5 '>Recharge</button></Link>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className='flex   items-center '>Available Balance : <LiaRupeeSignSolid className='text-xl' />{localStorage.getItem("token") && JSON.parse(localStorage.getItem("user")).balance}  </p>
                <IoMdRefresh onClick={() => {
                  window.location.reload(false)
                }} className='text-2xl cursor-pointer text-slate-400' />
              </div>

            </div>
          </div>
          <div className="flex justify-center overflow-auto w-[100%] md:w-[50%] control-box items-center  flex-row  justify-evenly">
            <input type="radio" id="contract1" name="hosting" value={1} className="hidden peer" required onChange={handleOptionChange} />
            <label htmlFor="contract1" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 1 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Update</div>
              </div>

            </label>
            <input type="radio" id="contract2x" name="hosting" value={22} className="hidden peer" onChange={handleOptionChange} />
            <input type="radio" id="contract2" name="hosting" value={2} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract2" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 2 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Recharge</div>

              </div>

            </label>

            <input type="radio" id="contract4" name="hosting" value={4} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract4" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 4 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">Userinfo</div>
              </div>

            </label>


          </div>
          {selectedOption == 1 &&
            <div className=" m-auto flex    h-[50vh] flex-col  mt-10 justify-center  w-[90%]   ">
              <div className="flex flex-col mb-10  w-full gap-10   items-center  justify-between  ">
                <div className="flex w-full  items-center gap-5  justify-between">
                  <p className='w-1/2'>Change result to Red </p>
                  <button onClick={() => {
                    setLoadingt(true)
                    cr(true)
                  }}
                    className=' flex items-center gap-3 justify-center pl-5 w-1/2 pr-5 pt-3 pb-3 border bg-red-500 active:bg-black text-white rounded'>
                    Red
                    <MoonLoader color={"white"} loading={Loadingt} size={20} aria-label="CircleLoader" /></button>
                </div>
                <div className="flex w-full  items-center gap-5  justify-between">
                  <p className='w-1/2'>Change result to Green </p>
                  <button onClick={() => {
                    setLoadingf(true)

                    cr(false)
                  }} className='flex items-center gap-3 justify-center pl-5 w-1/2  pr-5 pt-3 pb-3 border bg-green-500 active:bg-black text-white rounded'>Green

                    <MoonLoader color={"white"} loading={Loadingf} size={20} aria-label="CircleLo//ader" />

                  </button>
                </div>


              </div>
              <>
                {trueinfo.map((e) => {

                  return <div key={e._id}>
                    <div className="flex   p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Moneycontract</p>
                      <p className=' w-1/2 text-end'>{e.moneycontract}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>red</p>
                      <p className=' w-1/2 text-end'>{e.red}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>green</p>
                      <p className=' w-1/2 text-end'>{e.green}</p>
                    </div>
                  </div>
                })}
                <div className="flex items-center mt-10 justify-end">

                  <button onClick={trueresult} className='bg-purple-500 text-white  shadow active:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Update</button>
                </div>
              </>
            </div>}
          {selectedOption == 3 &&
            <>

              <div className="h-[50vh] flex items-center flex-col overflow-auto border mb-5 result-container pb-3 pr-3 pl-3 m-auto w-full md:w-[80%]">


                {withdrawals.map((element) => {
                  return <div className="md:w-[60%] mb-3 mt-3  w-[100%] " key={element._id} onClick={() => {

                  }}>
                    <div className="flex w-full  pt-2 pb-2 pr-2 pl-2 border  items-center  justify-between ">

                      <div className=" flex w-[100%] items-center  justify-between ">

                        <div className="w-1/3 "><p>{element.ammount}</p></div>


                        <div className="w-1/3 text-center" ><button onClick={(() => {
                          handleClick(element)
                          setaccept(false)
                        })} className='bg-red-500 text-white  shadow active:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Reject</button></div>
                        <div className="w-1/3 text-end"><button onClick={(() => {
                          handleClick(element)
                          setaccept(true)
                        })} className='bg-green-500 text-white shadow active:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Accept</button></div>

                      </div>
                      <div className="">
                      </div>
                    </div>
                    {selectedElement === element && (
                      <div className=" mb-2 mt-2 pl-2">
                        <div className="flex p-0.5 items-center  justify-between ">
                          <p className=' w-1/2 '>Amount</p>
                          <p className=' w-1/2'>{element.ammount}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>Bank card</p>
                          <p className=' w-1/2'>{element.upi}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>Status</p>
                          <div className={`${element.status == "Applied" ? "text-purple-700 " : ""}${element.status == "Success" ? "text-green-700 " : ""}${element.status == "Reject" ? "text-red-700 " : ""}  w-1/2`}><p>{element.status}</p></div>

                        </div>
                        <div className="flex items-center  justify-end">

                          {accept ? <button onClick={handleaccept} className='bg-green-500 text-white shadow hover:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Accept</button> :
                            <button onClick={handlereject} className='bg-red-500 text-white  shadow hover:bg-black  rounded pt-2 pb-2 pr-5 pl-5 btn'>Reject</button>}
                        </div>
                      </div>)}


                  </div>
                })}
              </div>
            </>

          }
          {selectedOption == 2 &&
            <>
              <div className='h-[100vh]  w-full md:w-[70%]'>

                <div className="flex items-center mt-10 mb-10 justify-center gap-2 pl-3 pr-3 pt-3 pb-3 pl-5 border shadow rounded-xl md:text-xl  font-bold">
                  <p className='  md:text-xl font-bold '>UTR :</p>
                  <input onChange={((e) => { SetUTR(e.target.value) })} maxLength={5} required type="number" className='placeholder:text-blue-500 ring-inset   focus:ring-2 focus:ring-inset focus:ring-blue-600 p-3 ' placeholder='Input 12-digit here' />
                </div>
                <div className="flex justify-center mb-10 mt-10">
                  <button disabled={Loadingrecharge} onClick={handlerecharge} className='pt-3 pb-3 pl-20 pr-20 text-white active:bg-black bg-blue-500 font-bold text-xl shadow flex items-center gap-3   border rounded-xl' >Recharge
                  <MoonLoader color={"white"} loading={Loadingrecharge} size={20} aria-label="CircleLoader" />
                  </button>
                </div>
                <div className="flex justify-center mb-10 mt-10">
                  <button disabled={Loadingallrecharges} onClick={getrecharges} className='pt-3 pb-3 pl-20 pr-20 active:bg-black flex items-center gap-3 text-white bg-purple-500 font-bold text-xl shadow  border rounded-xl' >Recharge Update

                    <MoonLoader color={"white"} loading={Loadingallrecharges} size={20} aria-label="CircleLoader" />

                  </button>
                </div>
                {recharges.map((element) => {
                  return <div className="md:w-[60%] mb-3 mt-3  w-[100%] " key={element._id} onClick={() => {

                  }}>
                    <div className="flex w-full  pt-2 pb-2 pr-2 pl-2 border  items-center  justify-between ">

                      <div className=" flex w-[100%] items-center  justify-between ">

                        <div className="w-1/3 "><p>{element.amount}</p></div>



                        <div className="w-1/3 text-end"><button onClick={(() => {
                          handleClick(element)
                          setaccept(true)
                        })} className='bg-slate-100  shadow active:bg-slate-200 rounded pt-2 pb-2 pr-5 pl-5 btn'>Details</button></div>

                      </div>

                    </div>
                    {selectedElement === element && (
                      <div className=" mb-2 mt-2 pl-2">
                        <div className="flex p-0.5 items-center  justify-between ">
                          <p className=' w-1/2 '>Amount</p>
                          <p className=' w-1/2'>{element.amount}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>UTR</p>
                          <p className=' w-1/2'>{element.UTR}</p>
                        </div>
                        <div className="flex  p-0.5 items-center  justify-between ">
                          <p className=' w-1/2'>Status</p>
                          <div className={` w-1/2`}><p>{element.status}</p></div>

                        </div>

                      </div>)}
                  </div>

                })}
              </div>

            </>}
          {selectedOption == 4 &&
            <div className='h-[50vh]'>
              <div className="flex items-center gap-5 mt-10 mb-20 justify-between ">
                <PhoneInput country={"in"} value={useremmail} onChange={(e) => setuseremmail("+" + e)} placeholder='Enter Phone Number' />

                <IoSearch className='text-purple-600 text-3xl cursor-pointer ' onClick={getuser} />
              </div>
              {user &&

                <div className=" mb-2  mt-2 pl-2">
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>ID</p>
                    <p className=' w-1/2'>{user._id}</p>
                  </div>
                  <div className="flex p-0.5 items-center  justify-between ">
                    <p className=' w-1/2 '>Name</p>
                    <p className=' w-1/2'>{user.name}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Phone</p>
                    <p className=' w-1/2'>{user.email}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Bank card</p>
                    <p className=' w-1/2'>{user.upi}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Blanace</p>
                    <p className=' w-1/2'>{user.balance}</p>
                  </div>



                </div>
              }
            </div>}
          {selectedOption == 5 &&
            <div className='h-[70vh]  pl-2  pr-2'>
              <div className="flex items-center gap-5 mt-10 mb-20 justify-between ">
                <PhoneInput country={"in"} value={useremmail} onChange={(e) => setuseremmail("+" + e)} placeholder='Enter Phone Number' />

                <IoSearch className='text-purple-600 text-3xl cursor-pointer ' onClick={getuser} />
              </div>
              {user &&
                <>
                  <div className=" mb-2 text-sm  mt-2 ">
                    <div className="flex p-0.5 items-center   ">
                      <p className=' w-1/2 '>ID</p>
                      <p className=' w-1/2 '>{user._id}</p>
                    </div>
                    <div className="flex p-0.5 items-center  justify-between ">
                      <p className=' w-1/2 '>Name</p>
                      <p className=' w-1/2'>{user.name}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Email</p>
                      <p className=' w-1/2'>{user.email}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Bank card</p>
                      <p className=' w-1/2'>{user.upi}</p>
                    </div>
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Blanace</p>
                      <p className=' w-1/2'>{user.balance}</p>
                    </div>

                  </div>
                  <div className=" flex flex-col w-full mt-10">
                    <p className='mb-5'>Take action </p>

                    <input type="text" onChange={handletakeaction} className='border mb-10 rounded-xl pl-5 pr-5 pt-2 pb-2' placeholder='Enter balance...' />
                    <button onClick={takeaction} className='border pl-7 pr-7 pt-2 pb-2 bg-blue-600  rounded text-white active:bg-blue-500'>Confirm</button>
                  </div>
                </>

              }
            </div>}
          <div className="flex mt-20 items-center justify-center">
            <button onClick={(() => {
              setShowModal(true)

            })} className='bg-slate-100 shadow hover:bg-slate-300  rounded pt-2 pb-2 pr-5 pl-5 btn'>Log out</button>
          </div>
        </div>}
    </>
  )
}

export default Profile
