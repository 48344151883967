import React, { useEffect, useState } from 'react'
import Dashboard from './Dashboard'
import { useNavigate } from 'react-router-dom'
import { IoTrophyOutline } from "react-icons/io5"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleDown } from "react-icons/fa";
import { io } from 'socket.io-client';
import MoonLoader from "react-spinners/ClipLoader";

const socket = io(process.env.REACT_APP_HOST, {
  transports: ['websocket'],
  withCredentials: true,
  extraHeaders: {
    // 'Access-Control-Allow-Origin': 'https://www.money2x.in'
  }
});
socket.on('connect_error', (error) => {
  console.log('Connection Error:', error);
})
const ColorGame = () => {

  const history = useNavigate()
  const [Loading, setLoading] = useState(false);
  const [betTime, SetbetTime] = useState(0);
  const [period, SetPeriod] = useState(0);
  const TIME_LIMIT = 60 * 60 * 1000; 
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/Login")
    }
else{
  const lastShownTime = localStorage.getItem('lastModalShownTime');
  const currentTime = new Date().getTime();
  if (!lastShownTime || currentTime - lastShownTime > TIME_LIMIT) {
    // Show the modal when the socket connects
    socket.on('connect', () => {
      setshowModalinitial(true);
    });
  }
  socket.on("TimeUpdate", ({ currentTime, currentPeriod }) => {
    SetbetTime(currentTime)
    SetPeriod(currentPeriod)
  })
}
  }, [])
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/Login")
    }
    let conditon = true;
    if (conditon) {
      const universalresults = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/universalresults`, {
          method: 'GET',
          headers: {
            'Content-type': "application/json",

          },
        });
        const json = await response.json()
        let x = json.reverse()
        let newArray = x.slice(0, 30);
        setOldResults(newArray)

      }
      const userorders = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/userorders`, {
          method: 'GET',
          headers: {
            'Content-type': "application/json",
            "auth-token": localStorage.getItem("token")

          },
        });
        const json = await response.json()

        let x = json.reverse()
        let newArray = x.slice(0, 10);
        setmyorder(newArray)

      }
      const userorderdetails = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
          method: 'POST',
          headers: {
            'Content-type': "application/json",
            "auth-token": localStorage.getItem("token")
          },

        });
        const json = await response.json()

        if (json.success) {

          localStorage.setItem("user", JSON.stringify(json.user))
        }
      }
      userorders()
      userorderdetails()
      universalresults()
      conditon = false;
    }
  }, [period])
  const [showModal, setShowModal] = useState(false);
  const [showModalinitial, setshowModalinitial] = useState(false);
  const [MultiplyMoney, setMultiplyMoney] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalColor, setModalColor] = useState("");
  const [oldresults, setOldResults] = useState([])
  const [myOrder, setmyorder] = useState([])
  const [selectedElement, setSelectedElement] = useState(null);
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/Login")
    }
    else {

      const universalresults = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/universalresults`, {
          method: 'GET',
          headers: {
            'Content-type': "application/json",

          },
        });
        const json = await response.json()
        let x = json.reverse()
        let newArray = x.slice(0, 30);
        setOldResults(newArray)

      }
      const userorders = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/userorders`, {
          method: 'GET',
          headers: {
            'Content-type': "application/json",
            "auth-token": localStorage.getItem("token")

          },
        });
        const json = await response.json()
        let x = json.reverse()
        let newArray = x.slice(0, 10);
        setmyorder(newArray)
      }

      universalresults()
      userorders()
    }
  }, [])
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  const handleClick = (element) => {
    setSelectedElement((prevSelectedElement) =>
      prevSelectedElement === element ? null : element
    );

  };
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
      <div className='mb-20'>
        <Dashboard />
        {showModalinitial &&
          <>
            <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative  w-[90%] my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  
                  {/*body*/}
                  <div className="relative mt-5 items-center justify-center flex p-5 flex-auto">
                    <div className="flex flex-col  justify-center">
                      <p className='mb-5 text-sm'>Join our official channel for accurate daily predictions and maximize your earnings! Stay ahead with expert insights and boost your winning chances.</p>
                      <div className="flex  justify-around">

                      <button onClick={(()=>{
                        const currentTime = new Date().getTime();
                        localStorage.setItem('lastModalShownTime', currentTime);
                        setshowModalinitial(false)
                      })} 
                      className='pl-7 pr-7 text-sm pt-3 pb-3 bg-gray-100 shadow rounded'>Close</button>
                      <button onClick={(()=>{
                        window.open("https://t.me/money2xofficialprediction", "_blank")
                      })} className='pl-7 pr-7 text-sm pt-3 pb-3 bg-blue-500 text-white shadow-xl rounded' >Join</button>
                      </div>
                    </div>

                  </div>
                  
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40  bg-black"></div>
          </>
        }
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-[90%] my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className={`flex ${modalColor === "Red" && "bg-red-500"} ${modalColor === "Violet" && "bg-purple-500"} ${modalColor === "Green" && "bg-green-500"} text-white items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t`}>
                    <h3 className="text-2xl  ">
                      Join {modalColor}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">

                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative items-center justify-center flex p-5 flex-auto">
                    <div className="flex justify-center   items-center    md:w-[50%] w-[90vw]    flex-wrap  gap-4 ">
                      <div className="flex justify-center  mb-5 items-center  flex-row  justify-evenly">
                        <input type="radio" id="contract1" name="hosting" value={10} className="hidden peer" required onChange={handleOptionChange} />
                        <label htmlFor="contract1" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 10 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
                          <div className="block">
                            <div className="w-full text-lg font-semibold">10</div>
                          </div>

                        </label>
                        <input type="radio" id="contract2" name="hosting" value={100} className="hidden peer" onChange={handleOptionChange} />
                        <label htmlFor="contract2" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 100 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
                          <div className="block">
                            <div className="w-full text-lg font-semibold">100</div>
                          </div>

                        </label>
                        <input type="radio" id="contract3" name="hosting" value={1000} className="hidden peer" onChange={handleOptionChange} />
                        <label htmlFor="contract3" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 1000 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
                          <div className="block">
                            <div className="w-full text-lg font-semibold">1000</div>
                          </div>

                        </label>
                        <input type="radio" id="contract4" name="hosting" value={10000} className="hidden peer" onChange={handleOptionChange} />
                        <label htmlFor="contract4" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 10000 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
                          <div className="block">
                            <div className="w-full text-lg font-semibold">10000</div>
                          </div>

                        </label>
                      </div>
                      <div className="flex  w-full mb-5   items-center justify-center  ">
                        <p className=''>Numbers : </p>
                        <div className="  flex mx-3 gap-1 items-center justify-center ">
                          <p className='pt-1  border pb-1 pl-4 pr-4 cursor-pointer' onClick={(() => {
                            if (MultiplyMoney == 1) {

                            }
                            else {

                              setMultiplyMoney(MultiplyMoney - 1)

                            }
                          })}>-</p>
                          <p className='pt-1  pb-1 pl-3 pr-3'>{MultiplyMoney}</p>
                          <p className='pt-1 border pb-1 pl-4 pr-4 cursor-pointer' onClick={(() => {
                            if (selectedOption != null) {

                              setMultiplyMoney(MultiplyMoney + 1)
                            }
                          })}>+</p>
                        </div>
                      </div>
                      <div className=" flex items-center justify-center w-full ">
                        <p>Total Contrcat money is {selectedOption * MultiplyMoney} </p>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded ">
                    <button
                      className="mx-5 bg-slate-100 pt-2 pb-2 pr-3 pl-3 rounded shadow"
                      type="button"
                      onClick={(() => {
                        setShowModal(false)
                        setMultiplyMoney(1)
                        setSelectedOption(null)
                      })}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 pt-2 pb-2 pr-3 pl-3 flex items-center gap-3  rounded text-white font-bold shadow-xl "
                      type="submit"
                      disabled={betTime <= 30 || Loading}
                      onClick={(async () => {
                        setLoading(true)
                        if (selectedOption * MultiplyMoney == 0) {
                          toast.info("Please Select amount", {
                            position: "top-left",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,

                          });
                          setLoading(false)
                        }
                        else if (selectedOption * MultiplyMoney > (JSON.parse(localStorage.getItem("user")).balance) + (JSON.parse(localStorage.getItem("user")).GameCredits)) {
                          toast.error("Balance is not enough", {
                            position: "top-left",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,

                          });
                          setLoading(false)
                        }
                        else {
                          const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/placeorder`, {
                            method: 'POST',
                            headers: {
                              'Content-type': "application/json",
                              "auth-token": localStorage.getItem("token")

                            },
                            body: JSON.stringify({ ammount: selectedOption * MultiplyMoney, color: modalColor === "Red" ? true : false, period: period })
                          });
                          const json = await response.json()
                          if (json.success) {

                            toast.success("Confirm Bet", {
                              position: "top-left",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,

                            });
                            const userorderdetails = async () => {
                              const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
                                method: 'POST',
                                headers: {
                                  'Content-type': "application/json",
                                  "auth-token": localStorage.getItem("token")
                                },

                              });
                              const json = await response.json()

                              if (json.success) {

                                localStorage.setItem("user", JSON.stringify(json.user))
                              }
                            }
                            userorderdetails()
                            const userorders = async () => {
                              const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/userorders`, {
                                method: 'GET',
                                headers: {
                                  'Content-type': "application/json",
                                  "auth-token": localStorage.getItem("token")

                                },
                              });
                              const json = await response.json()
                              let x = json.reverse()
                              let newArray = x.slice(0, 15);
                              setmyorder(newArray)
                            }
                            userorders()
                            setLoading(false)
                          }
                          else {
                            toast.error("Erorr", {
                              position: "top-left",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,

                            });
                          }
                          setMultiplyMoney(1)
                          setSelectedOption(null)
                          setShowModal(false)
                        }
                      })}
                    > <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40  bg-black"></div>

          </>
        ) : null}

        <div className="p-3 mb-2 m-auto w-full md:w-[60%]  ">

          <div className="flex items-center justify-between">
            <div className=" mb-3 gap-2 text-xl flex items-center justify-between">
              <IoTrophyOutline />
              <p>Period</p>

            </div>
            <div className="text-xl ">
              <p>Count Down</p>
            </div>
          </div>
          <div className="flex   items-center justify-between">
            <div className=" gap-2 text-xl flex items-center justify-between">
              <p>{period}</p>

            </div>
            <div className="text-xl  flex">
              <p>{formatTime(betTime)}</p>
            </div>
          </div>
        </div>
        <div className="p-3 mb-2 m-auto w-full md:w-[60%]">
          <div className="flex items-center justify-between">
            <button disabled={betTime <= 30 || Loading} onClick={(() => {
              setShowModal(true)

              setModalColor("Red")
            })} className={`pt-2 ${betTime <= 30 ? " bg-slate-400 " : ""}  shadow-xl pb-2 pl-3 pr-3  bg-red-600 text-white font-bold rounded`}>Join Red</button>
            <button disabled={betTime <= 30 || Loading} onClick={(() => {
              setShowModal(true)
              setModalColor("Violet")
            })} className={`pt-2 ${betTime <= 30 ? " bg-slate-400 " : ""}  shadow-xl pb-2 pl-3 pr-3  bg-purple-600 text-white font-bold rounded`}>Join Violet</button>
            <button disabled={betTime <= 30 || Loading} onClick={(() => {
              setShowModal(true)

              setModalColor("Green")
            })} className={`pt-2 ${betTime <= 30 ? " bg-slate-400 " : ""} shadow-xl pb-2 pl-3 pr-3  bg-green-600 text-white font-bold rounded`}>Join Green</button>
          </div>
        </div>
        <div className="h-[50vh] text-sm md:text-base overflow-auto border mb-5 result-container pb-3 pr-3 pl-3 m-auto w-full md:w-[60%]">
          <div className="flex cursor-pointer sticky top-0 bg-white  pt-2 pb-2  items-center text-xl justify-between ">
            <div className=" w-1/4 "><p>Period</p></div>
            <div className=" w-1/5 "><p>Price</p></div>
            <div className=" w-1/4  text-center "><p>Result</p></div>
          </div>

          {oldresults.map((m) => {
            return <div key={m._id} className="flex  pt-2 pb-2 pr-1 pl-1 border  items-center  justify-between ">
              <div className=" w-1/4 pl-1"><p>{m.period}</p></div>
              <div className=" w-1/5 "><p>{m.price}</p></div>
              <div className=" w-1/4 flex items-center justify-center">
                {m.result ? <p className='rounded-full flex items-center  justify-center md:justify-start shadow-xl bg-red-500 h-4 mx-1 w-4'></p>
                  : <p className='rounded-full flex items-center  justify-center md:justify-start shadow-xl bg-green-500 h-4 mx-1 w-4'></p>}
                {m.voilet && <p className='rounded-full flex items-center  justify-center md:justify-start shadow-xl bg-purple-500 h-4 w-4'></p>}
              </div>

            </div>
          })}

        </div>
        <div className="h-[50vh] text-sm z-10 overflow-auto  mb-5 records-container pb-3 pr-3 pl-3 m-auto w-full md:w-[60%]">
          <div className="left-0  sticky top-0 bg-white pb-2  flex items-center justify-center mb-5">
            <p className=' cursor-pointer '>My Records</p>
          </div>

          {myOrder.map((element) => {
            return <div className="" key={element._id} onClick={() => {
              handleClick(element)

            }}>
              <div className="flex  pt-2 pb-2 pr-1 pl-1 border  items-center  justify-between ">

                <div className=" flex w-[80%] items-center  justify-between ">

                  <div className=""><p>{element.period}</p></div>
                  <div className={`${element.status == "Wait" ? "text-yellow-500 " : ""} ${element.status == 'Loss' ? "text-red-500" : ""}${element.status == 'Success' ? "text-green-500" : ""}   w-1/6`}><p>{element.status}</p></div>
                  {element.status != "Wait" && <div className={` ${element.status == "Wait" ? "text-yellow-500 " : element.status == "Success" ? "text-green-500 " : "text-red-500 "} w-1/6`}><p>{element.status == "Success" ? "+" : "-"}{element.status == "Success" ? element.ammount : element.moneycontract}</p></div>}
                </div>
                <div className="">
                  <FaAngleDown className='text-slate-400 hover:transition hover:duration-300 hover:rotate-180' />
                </div>
              </div>
              {selectedElement === element && (
                <div className=" mb-2 mt-2 pl-2">
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Period</p>
                    <p className=' w-1/2'>{element.period}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Money Contract</p>
                    <p className=' w-1/2'>{element.moneycontract}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Delivery</p>
                    <p className=' w-1/2'>{element.delivery}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Fee</p>
                    <p className=' w-1/2'>{element.fee}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Select</p>
                    <p className={`w-1/2 ${element.color ? "text-red-500" : "text-green-500"}`}>{element.color ? "red" : "green"}</p>
                  </div>
                  {element.status !== "Wait" &&
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Result</p>
                      <p className={`w-1/2 flex gap-2 ${element.result ? "text-red-500" : "text-green-500"}`}>{element.result ? "red" : "green"}
                        {element.voilet && <p className="text-purple-600" >{"violet"}</p>}

                      </p>
                    </div>
                  }
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Status</p>
                    <p className={`w-1/2 ${element.status == "Wait" ? "text-yellow-500 " : ""} ${element.status == 'Loss' ? "text-red-500" : ""}${element.status == 'Success' ? "text-green-500" : ""} `}>{element.status}</p>
                  </div>
                  {element.status !== "Wait" &&
                    <div className="flex  p-0.5 items-center  justify-between ">
                      <p className=' w-1/2'>Amount</p>
                      <p className={` w-1/2  ${element.status == 'Loss' ? "text-red-500" : ""}${element.status == 'Success' ? "text-green-500" : ""} `}>{element.status == "Success" ? "+" : ""}{element.ammount}</p>
                    </div>
                  }
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Created At </p>
                    <div className="w-1/2"><p>{new Date(element.createdAt).toLocaleString('en-GB', { year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(',', '')}</p></div>


                  </div>
                </div>
              )}

            </div>
          })}



        </div>
      </div>
    </>
  )
}

export default ColorGame
