import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css"
import MoonLoader from "react-spinners/ClipLoader";

const Login = () => {
  let history = useNavigate();
  const [credentials, setCredentials] = useState({ password: "" });
  const [ph, setph] = useState("")
  const [Loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {

    e.preventDefault();
    const { password } = credentials;
    setLoading(true)

    if (ph == "") {
      toast.info('Enter details first !', {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      setLoading(false)
    }
    else {


      const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-type': "application/json",
        },
        body: JSON.stringify({ email: ph, password })
      });
      const json = await response.json()

      if (json.success) {
        setLoading(false)
        localStorage.setItem('token', json.authToken)
        localStorage.setItem("user", JSON.stringify(json.user))


        setTimeout(() => {

          history("/")
        }, 1000);
        toast.success('Login in Successfuly !', {
          position: "top-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });

      }
      else {
        setLoading(false)
        toast.error(json.error ? json.error : json.errors[0].msg, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
    }
  }
  return (
    <div className='h-screen'>
      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
      <div className="flex   md:mt-0 min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10  sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" onSubmit={handleSubmit} >
            <div className="flex flex-col  items-center">


              <div className="mt-2  flex justify-center items-center">
                <PhoneInput className="" required autoComplete="phone" id="email" name="email" country={"in"} value={ph} onChange={(e) => setph("+" + e)} placeholder='Enter Phone Number' />

              </div>

            </div>

            <div>

              <div className="mt-2 flex  items-center justify-center ">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={onChange}
                  required
                  placeholder='Enter Password'
                  className="block w-[100%] md:w-[80%] pl-2 border-1 rounded py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className='flex items-center  flex-col gap-5'>
              <button
                type="submit"
                disabled={Loading}
                className="flex w-[100%] md:w-[85%] justify-center items-center gap-3   bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Sign in
                <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />
              </button>
              <div className="text-sm w-full  text-end ">
                <Link to="/forgot" className="font-semibold text-blue-600 hover:text-blue-500">
                  Forgot password?
                </Link>
              </div>
            </div>

          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Don't have an account?
            <Link to="/Signup" className="font-semibold mx-2 leading-6 text-blue-600 hover:text-blue-500">
              Create account
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Login
